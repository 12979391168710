* {
  padding: 0;
  margin: 0;
}

.App {
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.text-wrapper {
  width: 80%;
}

.fade-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.parallax-outer {
  position: relative;
  z-index: 1;
}

.blueText {
  color: #00ACE7;
}

.marker-btn {
  margin: 0;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background-color: #333;
}

.hover {
  position: relative;
}

.hover:hover .invisible {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.hover:hover .visible {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.visible {
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 100px;
}

.invisible {
  position: absolute;
  top: 100px;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}